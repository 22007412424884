import React from "react";
import Styles from "./styles";
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { api } from "../../services";
import { CustomText, Footer } from "../../genericComponents";
import { Icons, Spacing } from "../../config";
import fonts from "../../config/fonts";
import { hooks, SessionStorage } from "../../utils";

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import InputMask from "react-input-mask";

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Select from '@material-ui/core/Select';

import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import NotInterestedTwoToneIcon from '@material-ui/icons/NotInterestedTwoTone';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import IndeterminateCheckBoxTwoToneIcon from '@material-ui/icons/IndeterminateCheckBoxTwoTone';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Route } from "react-router-dom";


import Hidden from '@material-ui/core/Hidden';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from "react-router-dom";
import { paths } from "../../navigation/navigate";


import './cadastro.css';
import { CircularProgress, Grid } from "@material-ui/core";

import UF from './estadosbrasileiros.json';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const apiMiddleware = process.env.REACT_APP_API_MIDDLEWARE;

const GridWithSSM = ({ ssm, ...other }) => {
  const ssmClass = `MuiGrid-grid-ssm-${ssm}`;
  return <Grid className={ssmClass} {...other} />;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(ibge, municipios, theme) {
    return {
      fontWeight:
        municipios.indexOf(ibge) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  

function getSteps() {
    return ['Formulário Preenchido', 'Documentos enviados', 'Cadastro Aprovado', 'Contrato Assinado'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
        return 'Select campaign settings...';
        case 1:
        return 'What is an ad group anyways?';
        case 2:
        return 'This is the bit I really care about!';
        default:
        return 'Unknown stepIndex';
    }
}
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
        position: 'absolute',
        width: '98%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: '95%',
        overflow: 'auto'
    },
  }));

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

  

export default function Cadastro() {
  const { loading, call } = hooks.useRequest();

  const [reportTypes, SetReportTypes] = React.useState(null);
  const [listReports, setReports] = React.useState(null);
  const [mounted, setMounted] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [documentoenviados, setdocumentoenviados] = React.useState(0);
  const steps = getSteps();
  const classes = useStyles();
  const user_id = SessionStorage.getItem("user_id");
  const navigate = useNavigate();
  
  const [opendialogsubmeter, setopendialogsubmeter] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [modalStyle] = React.useState(getModalStyle);

  const userEmail = SessionStorage.getItem("email");
  // const { setPathPageView, setIdentity } = useTrackingCode();
  // setPathPageView("/cadastro");
  // setIdentity(userEmail);
  
  const handleClickopendialogsubmeter = () => {
    setopendialogsubmeter(true);
  };

  const handleCloseopendialogsubmeter = () => {
    setopendialogsubmeter(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleCliente = (event) => {
    setCliente(event.target.value);
  };
  const [cliente, setCliente] = React.useState([]);
  const [dadosParceiro, setdadosParceiro] = React.useState([]);
  const [cnpj, setcnpj] = React.useState([]);
  const [idrepresentante, setidrepresentante] = React.useState('');
  
  const [nomerepresentante, setnomerepresentante] = React.useState('');
  const handlenomerepresentante = (event) => {
    setnomerepresentante(event.target.value);
  };
  const [nomerepresentante2, setnomerepresentante2] = React.useState('');
  const handlenomerepresentante2 = (event) => {
    setnomerepresentante2(event.target.value);
  };
  const [emailrepresentante, setemailrepresentante] = React.useState('');
  const handleemailrepresentante = (event) => {
    setemailrepresentante(event.target.value);
  };
  const [emailrepresentante2, setemailrepresentante2] = React.useState('');
  const handleemailrepresentante2 = (event) => {
    setemailrepresentante2(event.target.value);
  };
  const [cargorepresentante, setcargorepresentante] = React.useState('');
  const handlecargorepresentante = (event) => {
    setcargorepresentante(event.target.value);
  };

  const handlecnpj = (event) => {
    setcnpj(event.target.value);
  };
  const [razao_social,setrazao_social] = React.useState('');
  const handlerazao_social = (event) => {
    setrazao_social(event.target.value);
  };
  const [endereco, setendereco] = React.useState([]);
  const handleendereco = (event) => {
    setendereco(event.target.value);
  };
  const [numero, setnumero] = React.useState([]);
  const handlenumero = (event) => {
    setnumero(event.target.value);
  };
  const [complemento, setcomplemento] = React.useState([]);
  const handlecomplemento = (event) => {
    setcomplemento(event.target.value);
  };
  const [bairro, setbairro] = React.useState([]);
  const handlebairro = (event) => {
    setbairro(event.target.value);
  };
  const [cidade, setcidade] = React.useState([]);
  const handlecidade = (event) => {
    setcidade(event.target.value);
  };
  const [uf, setuf] = React.useState([]);
  const handleuf = (event) => {
    setuf(event.target.value);
    call(null, api.listaMunicipios(event.target.value), (responsemun) => {
        if(responsemun.ok) {
          setlistamunicipios(responsemun.data);
      }
   });
  };
  const [cep, setcep] = React.useState([]);
  const handlecep = (event) => {
    setcep(event.target.value);
  };

  const [telefone, settelefone] = React.useState([]);
  const handletelefone = (event) => {
    settelefone(event.target.value);
  };
  const [banco, setbanco] = React.useState([]);
  const handlebanco = (event) => {
    setbanco(event.target.value);
  };
  const [agencia, setagencia] = React.useState([]);
  const handleagencia = (event) => {
    setagencia(event.target.value);
  };
  const [conta, setconta] = React.useState([]);
  const handleconta = (event) => {
    setconta(event.target.value);
  };
  const [atuacao, setatuacao] = React.useState([]);
  const handleatuacao = (event) => {
    setatuacao(event.target.value);
  };
  const [associacao, setassociacao] = React.useState([]);
  const handleassociacao = (event) => {
    setassociacao(event.target.value);
  };
  const [meio, setmeio] = React.useState([]);
  const [parceiro_id, setparceiro_id] = React.useState([]);
  const handlemeio = (event) => {
    setmeio(event.target.value);
  };
  const [municipios, setmunicipios] = React.useState([]);
  const handlemunicipios = (event) => {
    setmunicipios(event.target.value);
  };

  const [listamunicipios, setlistamunicipios] = React.useState([]);


  const selectFile = (event) => {
      console.log(event.target.files);
  } 
  
  
  const [receitaFederalFile, setreceitaFederalFile] = React.useState([]);
  const receitaFederalUpload = (event) => {
      console.log(event.target.files[0].name);
      setreceitaFederalFile(event.target.files);
  } 
  const limpaReceita = () => {
    setreceitaFederalFile([]);
  }
  const enviareceitaFederal = () => {
    const formData = new FormData();
    console.log(receitaFederalFile[0]);
    formData.append("arquivo", receitaFederalFile[0]);
    formData.append("user_id", user_id);
    formData.append("tipo_id", 1);

    call(null, api.enviaArquivo(formData), (response) => {
        if(response.ok) {
            console.log(response.data);
            verificareceitaFederal();
        }
    });
  }
  const [documentoreceitaFederal, setdocumentoreceitaFederal] = React.useState([]);
  const [iddocumentoreceitaFederal, setiddocumentoreceitaFederal] = React.useState(0);
  

  const verificareceitaFederal = () => {
    call(null, api.verificaDocumentos(user_id, 1), (response) => {
        if(response.ok) {
            if(response.data.id) {
                setdocumentoreceitaFederal(response.data.status);
                setiddocumentoreceitaFederal(response.data.id);
            }
        }
    });
  }

  const deletaRepresentante = () => {
    console.log('DELETA REPRESENTANTE !');
    var representantedel = {"id": (idrepresentante)};

    call(null, api.excluirepresentante(representantedel), (response) => {
        if(response.ok) {
            listarepresentantes(parceiro_id);
            setidrepresentante('');
            setnomerepresentante('');
            setemailrepresentante('');
            setcargorepresentante('');
            handleCloseopendialogsubmeter();
            toastrepresentantedel();
        }
    });

  }
  
  const adicionarrepresentante = () => {
    console.log(nomerepresentante);
    console.log(emailrepresentante);
    console.log(parceiro_id);

    if(nomerepresentante == ''
        || emailrepresentante == ''
        || cargorepresentante == '') {
            toastOps('Todos os campos devem estar preenchidos');
    } else {
        var representante = {"nome":(nomerepresentante),"email":(emailrepresentante),"idparceiro":(parceiro_id), "id": (idrepresentante), "cargo": (cargorepresentante)};
        
        call(null, api.cadastrorepresentante(representante), (response) => {
            if(response.ok) {
                console.log(response.data);
                listarepresentantes(parceiro_id);
                setidrepresentante('');
                setnomerepresentante('');
                setemailrepresentante('');
                setcargorepresentante('');
                toastrepresentante();
            }
        });
    }
    
  }
  
  const limparrepresentante = () => {
    setidrepresentante('');
    setnomerepresentante('');
    setemailrepresentante('');
    setcargorepresentante('');
  }
  
  const listarepresentantes = () => {
    call(null, api.listarepresentantes(parceiro_id), (response) => {
        if(response.ok) {
            console.log(response.data);
            setrows(response.data);
        }
    });
  }
  

  const [certidaoFalenciaFile, setcertidaoFalenciaFile] = React.useState([]);
  
  const certidaoFalenciaUpload = (event) => {
      console.log(event.target.files[0].name);
      setcertidaoFalenciaFile(event.target.files);
  } 
  const limpacertidaoFalencia = () => {
    setcertidaoFalenciaFile([]);
  }
  const enviacertidaoFalencia = () => {
    const formData = new FormData();
    console.log(certidaoFalenciaFile[0]);
    formData.append("arquivo", certidaoFalenciaFile[0]);
    formData.append("user_id", user_id);
    formData.append("tipo_id", 2);

    call(null, api.enviaArquivo(formData), (response) => {
        if(response.ok) {
            console.log(response.data);
            verificacertidaoFalencia();
        }
    });
  }
  const [documentocertidaoFalencia, setdocumentocertidaoFalencia] = React.useState([]);
  const [iddocumentocertidaoFalencia, setiddocumentocertidaoFalencia] = React.useState([]);

  const verificacertidaoFalencia = () => {
    call(null, api.verificaDocumentos(user_id, 2), (response) => {
        if(response.ok) {
            if(response.data.id) {
                setdocumentocertidaoFalencia(1);
                setiddocumentocertidaoFalencia(response.data.id);
            }
        }
    });
  }

  const [certidaoTributosFile, setcertidaoTributosFile] = React.useState([]);
  
  const certidaoTributosUpload = (event) => {
      console.log(event.target.files[0].name);
      setcertidaoTributosFile(event.target.files);
  } 
  const limpacertidaoTributos = () => {
    setcertidaoTributosFile([]);
  }
  const enviacertidaoTributos = () => {
    const formData = new FormData();
    console.log(certidaoTributosFile[0]);
    formData.append("arquivo", certidaoTributosFile[0]);
    formData.append("user_id", user_id);
    formData.append("tipo_id", 3);

    call(null, api.enviaArquivo(formData), (response) => {
        if(response.ok) {
            console.log(response.data);
            verificacertidaoTributos();
        }
    });
  }
  const [documentocertidaoTributos, setdocumentocertidaoTributos] = React.useState([]);
  const [iddocumentocertidaoTributos, setiddocumentocertidaoTributos] = React.useState([]);
  const verificacertidaoTributos = () => {
    call(null, api.verificaDocumentos(user_id, 3), (response) => {
        if(response.ok) {
            if(response.data.id) {
                setdocumentocertidaoTributos(1);
                setiddocumentocertidaoTributos(response.data.id);
            }
        }
    });
  }
  
  const [certificadoRegularidadesFile, setcertificadoRegularidadesFile] = React.useState([]);

  const certificadoRegularidadesUpload = (event) => {
      console.log(event.target.files[0].name);
      setcertificadoRegularidadesFile(event.target.files);
  } 
  const limpacertificadoRegularidades = () => {
    setcertificadoRegularidadesFile([]);
  }
  const enviacertificadoRegularidades = () => {
    const formData = new FormData();
    console.log(certificadoRegularidadesFile[0]);
    formData.append("arquivo", certificadoRegularidadesFile[0]);
    formData.append("user_id", user_id);
    formData.append("tipo_id", 4);

    call(null, api.enviaArquivo(formData), (response) => {
        if(response.ok) {
            console.log(response.data);
            verificacertificadoRegularidades();
        }
    });
  }
  const [documentocertificadoRegularidades, setdocumentocertificadoRegularidades] = React.useState([]);
  const [iddocumentocertificadoRegularidades, setiddocumentocertificadoRegularidades] = React.useState([]);

  const verificacertificadoRegularidades = () => {
    call(null, api.verificaDocumentos(user_id, 4), (response) => {
        if(response.ok) {
            if(response.data.id) {
                setdocumentocertificadoRegularidades(1);
                setiddocumentocertificadoRegularidades(response.data.id);
            }
        }
    });
  }

  const [formularioComplienceFile, setformularioComplienceFile] = React.useState([]);

  const formularioComplienceUpload = (event) => {
      console.log(event.target.files[0].name);
      setformularioComplienceFile(event.target.files);
  } 
  const limpaformularioComplience = () => {
    setformularioComplienceFile([]);
  }
  const enviaformularioComplience = () => {
    const formData = new FormData();
    console.log(formularioComplienceFile[0]);
    formData.append("arquivo", formularioComplienceFile[0]);
    formData.append("user_id", user_id);
    formData.append("tipo_id", 6);

    call(null, api.enviaArquivo(formData), (response) => {
        if(response.ok) {
            console.log(response.data);
            verificaformularioComplience();
        }
    });
  }
  const [documentoformularioComplience, setdocumentoformularioComplience] = React.useState([]);
  const [iddocumentoformularioComplience, setiddocumentoformularioComplience] = React.useState([]);

  const verificaformularioComplience = () => {
    call(null, api.verificaDocumentos(user_id, 6), (response) => {
        if(response.ok) {
            if(response.data.id) {
                setdocumentoformularioComplience(1);
                setiddocumentoformularioComplience(response.data.id);
            }
        }
    });
  }
  
  const [acordoParceriaFile, setacordoParceriaFile] = React.useState([]);

  const acordoParceriaUpload = (event) => {
      console.log(event.target.files[0].name);
      setacordoParceriaFile(event.target.files);
  } 
  const limpaacordoParceria = () => {
    setacordoParceriaFile([]);
  }
  const enviaacordoParceria = () => {
    const formData = new FormData();
    console.log(acordoParceriaFile[0]);
    formData.append("arquivo", acordoParceriaFile[0]);
    formData.append("user_id", user_id);
    formData.append("tipo_id", 7);

    call(null, api.enviaArquivo(formData), (response) => {
        if(response.ok) {
            console.log(response.data);
            verificaacordoParceria();
        }
    });
  }
  const [documentoacordoParceria, setdocumentoacordoParceria] = React.useState([]);
  const [iddocumentoacordoParceria, setiddocumentoacordoParceria] = React.useState([]);

  const verificaacordoParceria = () => {
    call(null, api.verificaDocumentos(user_id, 7), (response) => {
        if(response.ok) {
            if(response.data.id) {
                setdocumentoacordoParceria(response.data.status);
                setiddocumentoacordoParceria(response.data.id);
            }
        }
    });
  }
  
  const [contratosocialFile, setcontratosocialFile] = React.useState([]);
  const contratosocialUpload = (event) => {
      console.log(event.target.files[0].name);
      setcontratosocialFile(event.target.files);
  } 
  const limpacontratosocial = () => {
    setcontratosocialFile([]);
  }
  const enviacontratosocial = () => {
    const formData = new FormData();
    console.log(contratosocialFile[0]);
    formData.append("arquivo", contratosocialFile[0]);
    formData.append("user_id", user_id);
    formData.append("tipo_id", 10);

    call(null, api.enviaArquivo(formData), (response) => {
        if(response.ok) {
            console.log(response.data);
            verificacontratosocial();
        }
    });
  }
  const [documentocontratosocial, setdocumentocontratosocial] = React.useState([]);
  const [iddocumentocontratosocial, setiddocumentocontratosocial] = React.useState([]);

  const verificacontratosocial = () => {
    call(null, api.verificaDocumentos(user_id, 10), (response) => {
        if(response.ok) {
            if(response.data.id) {
                setdocumentocontratosocial(response.data.status);
                setiddocumentocontratosocial(response.data.id);
            }
        }
    });
  }


  const [contratoFile, setcontratoFile] = React.useState([]);
  const contratoUpload = (event) => {
      console.log(event.target.files[0].name);
      setcontratoFile(event.target.files);
  } 
  const limpacontrato = () => {
    setcontratoFile([]);
  }
  const enviacontrato = () => {
    const formData = new FormData();
    console.log(contratoFile[0]);
    formData.append("arquivo", contratoFile[0]);
    formData.append("user_id", user_id);
    formData.append("tipo_id", 8);

    call(null, api.enviaArquivo(formData), (response) => {
        if(response.ok) {
            console.log(response.data);
            verificacontrato();
        }
    });
  }
  const [documentocontrato, setdocumentocontrato] = React.useState([]);
  const [iddocumentocontrato, setiddocumentocontrato] = React.useState([]);

  const verificacontrato = () => {
    call(null, api.verificaDocumentos(user_id, 7), (response) => {
        if(response.ok) {
            if(response.data.id) {
                setdocumentocontrato(1);
                setiddocumentocontrato(response.data.id);
            }
        }
    });
  }

  const [arealista, setarealista] = React.useState([]);
  const [comochegoulista, setcomochegoulista] = React.useState([]);
  var cnvio = 0;

  const editarRepresentante = async (data) => {
    console.log(data);
    setidrepresentante(data.id);
    setnomerepresentante(data.nome);
    setemailrepresentante(data.email);
    setcargorepresentante(data.cargo);
  };
  
  const salvarCadastroParceiro = () => {
    if(contratosocialFile.length > 0) {
        enviacontratosocial();
        cnvio = cnvio + 1;
    }
    console.log(dadosParceiro);
    console.log(cnpj);
    console.log(razao_social);
    console.log(endereco);
    console.log(telefone);
    console.log(banco);
    console.log(agencia);
    console.log(conta);
    console.log(atuacao);
    console.log(municipios);
    console.log(associacao);
    console.log(meio);
    console.log(parceiro_id);
    
    var erro = '';

    if(endereco == '')  {
        erro = (erro == '') ? 'endereco' : erro + ', endereco';
    }
    if(telefone == '')  {
        erro = (erro == '') ? 'telefone' : erro + ', telefone';
    }
    if(banco == '')  {
        erro = (erro == '') ? 'banco' : erro + ', banco';
    }
    if(agencia == '')  {
        erro = (erro == '') ? 'agencia' : erro + ', agencia';
    }
    if(conta == '')  {
        erro = (erro == '') ? 'conta' : erro + ', conta';
    }
    if(atuacao == '')  {
        erro = (erro == '') ? 'área de atuacao' : erro + ', área de atuacao';
    }
    if(meio == '')  {
        erro = (erro == '') ? 'como chegou' : erro + ', como chegou';
    }

    if(erro == '') {
        var dados = {"endereco":(endereco),"telefone":(telefone),"banco":(banco),"agencia":(agencia),"como_conheceu_id":(meio),"area_id":(atuacao),"conta":(conta),"preenchido":"true","numero":(numero),"complemento":(complemento),"bairro":(bairro),"cidade":(cidade),"uf":(uf),"razao_social":(razao_social)};
    
        call(null, api.editarDadosParceiro(parceiro_id,dados), (response) => {
            if(response.ok) {
                console.log(response.data);
                toastcadastro();
                if(cnvio === 0) {
                    setActiveStep(1);
                } else {
                }
                
                verificacontratosocial();
                
            }
        });

        if(municipios.length > 0) {
            call(null, api.cadastroMultiplosMunicipios(parceiro_id,municipios), (response) => {
                if(response.ok) {
                    console.log(response.data);
                }
            });
        }
    } else{
        var msgErro = 'Os seguintes campos não podem ser nulos : ' + erro;
        toastOps(msgErro);
    }
    
  }

  const voltarDashboard = () => {
    navigate(paths.home);
  }

  const toastcadastro = () => {
    toast.success('Cadastro salvo com sucesso !', {
        position: toast.POSITION.TOP_CENTER
    });
  };

  const toastrepresentante = () => {
    toast.success('Representante adicionado com sucesso !', {
        position: toast.POSITION.TOP_CENTER
    });
  };
  const toastrepresentantedel = () => {
    toast.success('Representante deletado com sucesso !', {
        position: toast.POSITION.TOP_CENTER
    });
  };

  const toastOps = (message) => {
    toast.warning(message, {
        position: toast.POSITION.TOP_CENTER
    });
  };

  const [preenchido, setpreenchido] = React.useState([]);

  const verificaTodosDocumentos = () => {
    
    verificacontratosocial();
  }
  
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setidrepresentante('');
    setnomerepresentante('');
    setemailrepresentante('');
    setcargorepresentante('');

    listarepresentantes(parceiro_id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {id: 'nome',label: 'Nome',align: 'left'},
    {id: 'email',label: 'Email',align: 'left'}
  ];
  
  const [rows, setrows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
        <div className="barra-titulo">
            Cadastro de Representantes
        </div>
        <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={12} xl={3} md={3}>
                <FormControl fullWidth variant="outlined">
                    <TextField
                    id="nomerepresentante"
                    value={nomerepresentante}
                    onChange={handlenomerepresentante}
                    startAdornment={<InputAdornment position="start"></InputAdornment>}
                    labelWidth={60}
                    variant="outlined"
                    label="Nome Representante"
                    required
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} xl={3} md={3}>
                <FormControl fullWidth variant="outlined">
                    <TextField
                    id="emailrepresentante"
                    value={emailrepresentante}
                    onChange={handleemailrepresentante}
                    startAdornment={<InputAdornment position="start"></InputAdornment>}
                    labelWidth={60}
                    variant="outlined"
                    label="Email Representante"
                    required
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} xl={3} md={3}>
                <FormControl fullWidth variant="outlined">
                    <TextField
                    id="cargorepresentante"
                    value={cargorepresentante}
                    onChange={handlecargorepresentante}
                    startAdornment={<InputAdornment position="start"></InputAdornment>}
                    labelWidth={60}
                    variant="outlined"
                    label="Cargo Representante"
                    required
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} xl={3} md={3}>
                {idrepresentante !== ''
                &&
                    <Button
                        variant="contained"
                        color="primary"
                        className="botao-deletar-representante"
                        onClick={handleClickopendialogsubmeter}
                    >
                        <DeleteForeverOutlinedIcon/>
                        Deletar Representante
                    </Button>
                }
            </Grid>
        </Grid>
        <div className="botao-modal">
            <Button
                variant="contained"
                color="primary"
                className="botao-fechar"
                onClick={handleClose}
            >
                Fechar
            </Button>
            <Button
                variant="contained"
                color="primary"
                className="botao-salvar"
                onClick={adicionarrepresentante}
            >
                
                {
                    (idrepresentante == '') 
                    &&
                    <>
                        Adicionar representantes
                    </>
                }
                {
                    (idrepresentante != '') 
                    &&
                    <>
                        Salvar
                    </>
                }
            </Button>
            <Button
                variant="contained"
                color="primary"
                className="botao-voltar"
                onClick={limparrepresentante}
            >
                Limpar / Novo
            </Button>
            <TableContainer >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columns.map((column) => {
                          switch (column.id) {
                            case 'nome':
                              var valor = row.nome;
                              break;
                            case 'email':
                              var valor = row.email;
                              break;
                            default:
                              var valor = (<DeleteForeverOutlinedIcon></DeleteForeverOutlinedIcon>);
                              var icon = (<DeleteForeverOutlinedIcon></DeleteForeverOutlinedIcon>);
                              break;
                          }
                          return (
                            <TableCell key={column.id} align={column.align} onDoubleClick={()=>{editarRepresentante(row)}}>
                              {column.format && typeof value === 'number' ? column.format(valor) : valor}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              labelRowsPerPage = "Itens por página"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>

    </div>
  );

  const mount = React.useCallback(async () => {
    
    call(null, api.dadosDoParceiro(user_id), (response) => {
        if(response.ok) {
            setdadosParceiro(response.data);
            setcnpj(response.data.cnpj);
            setrazao_social(response.data.razao_social);
            setendereco(response.data.endereco);
            settelefone(response.data.telefone);
            setbanco(response.data.banco);
            setconta(response.data.conta);
            setatuacao(response.data.area_id);
            setassociacao(response.data.associacao);
            setmeio(response.data.como_conheceu_id);
            setparceiro_id(response.data.parceiro_id);
            setagencia(response.data.agencia);
            setconta(response.data.conta);
            setpreenchido(response.data.preenchido);
            setbairro(response.data.bairro);
            setcomplemento(response.data.complemento);
            setcidade(response.data.cidade);
            setuf(response.data.uf);
            setnumero(response.data.numero);

            if(response.data.preenchido) {
                setActiveStep(1);
            }

            call(null, api.verificaQtdDocumentos(user_id), (response) => {
                if(response.ok) {
                  console.log(response.data.qtd);
                  if(response.data.qtd > 0) {
                      setActiveStep(2);
                  }
              }
            });

            call(null, api.listaMunicipios(response.data.uf), (responsemun) => {
                if(responsemun.ok) {
                  setlistamunicipios(responsemun.data);
                  call(null, api.pegamunicipios(response.data.parceiro_id), (responsepegamun) => {
                       if(responsepegamun.ok) {
                         console.log(responsepegamun.data);
                         setmunicipios(responsepegamun.data);
                     }
                  });
                }
           });
            

            verificaTodosDocumentos();
        
        }
    });
    

      call(null, api.listaAreaAtuacao(), (response) => {
        if(response.ok) {
            setarealista(response.data);
          }
      });
      
      call(null, api.listaComoChegou(), (response) => {
        if(response.ok) {
            setcomochegoulista(response.data);
          }
      });
     

  }, [call]);

  React.useEffect(() => {
    if (!mounted) {
      mount();
      setMounted(true);
    }
  }, [mount, mounted]);

  var viewport_width = document.documentElement.clientWidth;

  return (
    <Styles.Container>
      <Styles.Content>
         <h1>
            Acompanhe o seu cadastro
         </h1>
          <p>
            Veja abaixo o status do seu cadastro e atualize os seus dados para seguir ganhando com a indicação de novos negócios
          </p>
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                <div>
                    <Typography className={classes.instructions}>Cadastro completo!</Typography>
                </div>
                ) : (
                <div>
                    <div className="box-voltar">   
                        <Button
                            variant="contained"
                            color="primary"
                            className="botao-voltar"
                            onClick={voltarDashboard}
                        >
                            Voltar
                        </Button>
                    </div>
                </div>
                )}
            </div>
        </div>
        <div className="barra-titulo">
            Documentos enviados
        </div>

        <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={12} xl={12} md={12}>
            <FormControl fullWidth variant="outlined">
                <TextField
                id="cnpj"
                value={cnpj}
                onChange={handlecnpj}
                startAdornment={<InputAdornment position="start"></InputAdornment>}
                labelWidth={60}
                variant="outlined"
                label="CPNJ"
                />
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} xl={12} md={12}>
            <FormControl fullWidth variant="outlined">
                <TextField
                id="razao_social"
                value={razao_social}
                onChange={handlerazao_social}
                startAdornment={<InputAdornment position="start"></InputAdornment>}
                labelWidth={60}
                variant="outlined"
                label="Razão Social"
                />
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} xl={8} md={8}>
            <FormControl fullWidth variant="outlined">
                <TextField
                id="endereco"
                value={endereco}
                onChange={handleendereco}
                startAdornment={<InputAdornment position="start"></InputAdornment>}
                labelWidth={60}
                variant="outlined"
                label="Endereço"
                />
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} xl={4} md={4}>
            <FormControl fullWidth variant="outlined">
                <TextField
                id="numero"
                value={numero}
                onChange={handlenumero}
                startAdornment={<InputAdornment position="start"></InputAdornment>}
                labelWidth={60}
                variant="outlined"
                label="Número"
                />
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} xl={6} md={6}>
            <FormControl fullWidth variant="outlined">
                <TextField
                id="comlemento"
                value={complemento}
                onChange={handlecomplemento}
                startAdornment={<InputAdornment position="start"></InputAdornment>}
                labelWidth={60}
                variant="outlined"
                label="Complemento"
                />
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} xl={6} md={6}>
            <FormControl fullWidth variant="outlined">
                <TextField
                id="bairro"
                value={bairro}
                onChange={handlebairro}
                startAdornment={<InputAdornment position="start"></InputAdornment>}
                labelWidth={60}
                variant="outlined"
                label="Bairro"
                />
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} xl={6} md={6}>
            <FormControl fullWidth variant="outlined">
                <TextField
                id="cidade"
                value={cidade}
                onChange={handlecidade}
                startAdornment={<InputAdornment position="start"></InputAdornment>}
                labelWidth={60}
                variant="outlined"
                label="Cidade"
                />
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} xl={6} md={6}>
            <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">UF</InputLabel>
                <Select
                    native
                    value={uf}
                    onChange={handleuf}
                    label="UF"
                    inputProps={{
                        name: 'uf',
                        id: 'outlined-age-native-simple',
                }}
                >
                <option value=""></option>
                {UF["UF"].map((est) => <option value={est['sigla']}>{est['sigla']}</option>)}
                                    
                </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} xl={12} md={12}>
            <FormControl fullWidth variant="outlined">
                <InputMask 
                    mask="(99) 9999-99999"
                    maskPlaceholder="-"
                    id="telefone"
                    value={telefone}
                    onChange={handletelefone}
                >
                    {() => <TextField
                                startAdornment={<InputAdornment position="start"></InputAdornment>}
                                labelWidth={60}
                                variant="outlined"
                                label="Telefone"
                            />}
                </InputMask>
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} xl={4} md={4}>
            <FormControl fullWidth variant="outlined">
                <TextField
                id="banco"
                value={banco}
                onChange={handlebanco}
                startAdornment={<InputAdornment position="start"></InputAdornment>}
                labelWidth={60}
                variant="outlined"
                label="Banco"
                />
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} xl={4} md={4}>
            <FormControl fullWidth variant="outlined">
                <TextField
                id="agencia"
                value={agencia}
                onChange={handleagencia}
                startAdornment={<InputAdornment position="start"></InputAdornment>}
                labelWidth={60}
                variant="outlined"
                label="Agência"
                />
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} xl={4} md={4}>
            <FormControl fullWidth variant="outlined">
                <TextField
                id="conta"
                value={conta}
                onChange={handleconta}
                startAdornment={<InputAdornment position="start"></InputAdornment>}
                labelWidth={60}
                variant="outlined"
                label="Conta"
                />
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} xl={12} md={12}>
            <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">Area de atuação</InputLabel>
                  <Select
                    native
                    value={atuacao}
                    onChange={handleatuacao}
                    label="Area de atuação"
                    inputProps={{
                      name: 'atuacao',
                      id: 'outlined-age-native-simple',
                    }}
                  >
                    <option value=""></option>
                    {arealista.map((ar) => <option value={ar['id']}>{ar['valor']}</option>)}
                                        
                  </Select>
            </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={12} xl={12} md={12}>
            <FormControl fullWidth variant="outlined">
                <TextField
                id="associacao"
                value={associacao}
                onChange={handleassociacao}
                startAdornment={<InputAdornment position="start"></InputAdornment>}
                labelWidth={60}
                variant="outlined"
                label="Participa de Associação? Se sim , qual?"
                />
            </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={12} xl={12} md={12}>
            <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">Como chegou na Ecom Energia?</InputLabel>
                  <Select
                    native
                    value={meio}
                    onChange={handlemeio}
                    label="Como chegou na Ecom Energia?"
                    inputProps={{
                      name: 'meio',
                      id: 'outlined-age-native-simple',
                    }}
                  >
                    <option value=""></option>
                    {comochegoulista.map((ar) => <option value={ar['id']}>{ar['valor']}</option>)}
                                        
                  </Select>
            </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={12} xl={12} md={12}>
            <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">Municipios de atuação</InputLabel>
                <Select
                    labelId="demo-mutiple-name-label"
                    id="demo-mutiple-name"
                    multiple
                    value={municipios}
                    onChange={handlemunicipios}
                    input={<Input />}
                    MenuProps={MenuProps}
                >
                {listamunicipios.map((mun) => (
                    <MenuItem key={mun['ibge']} value={mun['ibge']} style={getStyles(mun['ibge'], municipios, theme)}>
                        {mun['municipio']}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
            </Grid>
        </Grid>
        <div className="barra-titulo">
            <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={12} sm={12} xl={6} md={6} className="alinha-centro">
                    Documentos
                </Grid>
                <Grid item xs={12} sm={12} xl={4} md={4}>
                    
                </Grid>
                <Grid item xs={12} sm={12} xl={2} md={2} className="alinha-centro">
                    Status
                </Grid>
            </Grid>
        </div>
        <Grid container justifyContent="center" spacing={4} className="lista-upload">
            <Grid item xs={12} sm={12} xl={6} md={6}>
                Contrato social
                <Button
                    variant="contained"
                    color="primary"
                    className="botao-salvar"
                    onClick={handleOpen}
                    >
                    <GroupAddIcon />
                    Representantes
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} xl={4} md={4}>
            {(documentocontratosocial.length == 0)&&
            <>
            <input
                accept="image/jpeg,image/png,application/pdf"
                className="input-hidden"
                id="botao-contratosocial"
                multiple
                type="file"
                onChange={contratosocialUpload}
            />
            <label htmlFor="botao-contratosocial">
                {
                    contratosocialFile.length > 0 ?
                    <>
                        <InsertDriveFileIcon /> 
                        <span className="nome_arquivo">
                            {contratosocialFile[0].name}
                        </span>
                        <IconButton 
                            aria-label="delete"
                            className="lixeira"
                            onClick={limpaReceita}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </>
                    :
                    <Button variant="contained" color="primary" component="span">
                        Enviar Arquivo
                    </Button>
                }
            </label>
            </>
            }
            {(documentocontratosocial == 1 || documentocontratosocial == 2) &&
            <label htmlFor="botao-contratosocial">
                Documento enviado
                <IconButton 
                    aria-label="download documento"
                    onClick={() => {
                        window.open(apiMiddleware + 'api/downloadDocumentos/'+iddocumentocontratosocial, '_blank', 'noopener,noreferrer');
                    }}
                >
                    <CloudDownloadIcon />
                </IconButton>
            </label>}
            </Grid>
            <Grid item xs={12} sm={12} xl={2} md={2} className="alinha-centro">
                {documentocontratosocial.length == 0 &&
                    <NotInterestedTwoToneIcon className="icone-nao-enviado" />
                }
                {documentocontratosocial == 1 &&
                    <IndeterminateCheckBoxTwoToneIcon className="icone-avaliacao" />
                }
                {documentocontratosocial == 2 &&
                    <CheckCircleTwoToneIcon className="icone-aprovado" />
                }
                {documentocontratosocial == 3 &&
                    <HighlightOffTwoToneIcon className="icone-recusado" />
                }
            </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={4} className="lista-upload">
            <Grid item xs={12} sm={12} xl={6} md={6}>
                Acordo de parceria
            </Grid>
            <Grid item xs={12} sm={12} xl={4} md={4}>
            {(documentocontratosocial != 2)&&
            <p>Documento não anexado</p>
            }
            {documentocontratosocial == 2 &&
            <label htmlFor="botao-contrato">
                Visualizar documento
                <IconButton 
                    aria-label="download documento"
                    onClick={() => {
                        window.open(apiMiddleware + 'api/gerarPdfContrato/'+parceiro_id, '_blank', 'noopener,noreferrer');
                    }}
                >
                    <CloudDownloadIcon />
                </IconButton>
            </label>}
            </Grid>
            <Grid item xs={12} sm={12} xl={2} md={2} className="alinha-centro">
                
            </Grid>
        </Grid>
        
        <div className="barra-legenda">
            <Grid container justifyContent="center" spacing={4}>
            
                <Grid item xs={12} sm={12} xl={3} md={3}>
                    <CheckCircleTwoToneIcon className="icone-aprovado" />
                    <Typography className="legenda-titulo">Aprovado</Typography>
                </Grid>
                <Grid item xs={12} sm={12} xl={3} md={3}>
                    <IndeterminateCheckBoxTwoToneIcon className="icone-avaliacao" />
                    <Typography className="legenda-titulo">Em avaliação</Typography>
                </Grid>
                <Grid item xs={12} sm={12} xl={3} md={3}>
                    <HighlightOffTwoToneIcon className="icone-recusado" />
                    <Typography className="legenda-titulo">Reprovado</Typography>
                </Grid>
                <Grid item xs={12} sm={12} xl={3} md={3}>
                    <NotInterestedTwoToneIcon className="icone-nao-enviado" />
                    <Typography className="legenda-titulo">Não enviado</Typography>
                </Grid>
                
            </Grid>
        </div>
        <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={12} xl={6} md={6} className="botoes">
                <Button
                    variant="contained"
                    color="primary"
                    className="botao-salvar"
                    onClick={salvarCadastroParceiro}
                    >
                    Salvar
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} xl={6} md={6} className="botoes2">
                <Button
                    variant="contained"
                    color="primary"
                    className="botao-voltar"
                    onClick={voltarDashboard}
                >
                Voltar
                </Button>
            </Grid>
        </Grid>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disableScrollLock={false}
        >
        {body}   
        </Modal>
        <ToastContainer />
        <Dialog
                fullScreen={fullScreen}
                open={opendialogsubmeter}
                onClose={handleCloseopendialogsubmeter}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">{"Deletar o representante?"}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Tem certeza que deseja deletar o representante ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleCloseopendialogsubmeter} color="primary">
                    Não
                  </Button>
                  <Button onClick={deletaRepresentante} color="primary" autoFocus>
                    Sim
                  </Button>
                </DialogActions>
              </Dialog>
      </Styles.Content>
      <Footer />
    </Styles.Container>
  );
}
