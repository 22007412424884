import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import ptBr from "../../config/texts/pt-br";
import images from "../../config/images";
import {
  ButtonContained,
  CheckBox,
  CustomText,
  InputEmail,
  InputPassword,
} from "../../genericComponents";
import Styles from "./LoginStyles";
import { FormFull } from "form-full";
import { MediaQueries, Spacing } from "../../config";
import { validations } from "../../utils";
import { api } from "../../services";
import { hooks, SessionStorage } from "../../utils";
import { StorageContext } from "../../contexts/StorageContext";
import ImageLogin from "./image/imageLogin";
import ImageLogin2 from "./image/imageLogin2";
import packageJson from "../../../package.json";
import fonts from "../../config/fonts";
import Button from '@material-ui/core/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './login.css';

function Login() {
  const texts = ptBr.login;
  const version = packageJson.version;
  const navigate = useNavigate();
  const { loading, call } = hooks.useRequest();
  const { addData, setIsLogged } = useContext(StorageContext);
  const Submit = async (data) => {
    call(null, api.getToken(data), (response) => {
      console.log(response);
      if (response.ok) {
        
        console.log("JAVA TOKEN: " + response?.data?.type + " " + response?.data?.token);

        call(null, api.pegaToken(), (resposta) => {
          if(resposta.ok) {
            console.log("LARAVEL TOKEN: " + resposta?.data?.token_type + " " + resposta?.data?.access_token);
            SessionStorage.setItem(
              "middleware_token",
              resposta?.data?.token_type + " " + resposta?.data?.access_token
            );
          }
          call(null, api.verificaStatusParceiro(response.data.user.cpfCnpj, response.data.user.email), (ativo) => {
            if(ativo.ok) {
              if(ativo.data == 'ATIVO') {
                setIsLogged(true);
                console.log(response?.data?.user);
                addData("userData", response?.data?.user);
                SessionStorage.setItem(
                  "token",
                  response?.data?.type + " " + response?.data?.token
                );
                SessionStorage.setItem(
                  "email",
                  response?.data?.user.email
                );
                SessionStorage.setItem(
                  "user_id",
                  response?.data?.user.id
                );
                SessionStorage.setItem(
                  "avisoAssinatura",
                  'SIM'
                );
              } else {
                toastOps('O Parceiro com o CNPJ '+ response.data.user.cpfCnpj + ' está inativo, entre em contato com o administrador do sistema.');      
              }
            } else {
              toastOps('Ocorreu um erro ao validar o status do parceiro, entre em contato com o administrador do sistema.');      
            }
          });
        });
      } else{
        toastOps(response);
      }
    });
  };

  const [reminder, setReminder] = React.useState(false);
  const [buttonDisabled, setButton] = React.useState(true);

  const toastOps = (message) => {
    toast.warning(message, {
        position: toast.POSITION.TOP_CENTER
    });
  };

  return (
    <div className="bgfundo">

      <FormFull onSubmit={Submit} >
        <Grid
          container
          spacing={0}
          style={{
            height: "100vh",
            overflowX: "auto",
            display: "flex",
            flexDirection: "row",
            [MediaQueries.mdDown]: {
              flexDirection: "row-reverse",
            },
          }}>
          <Grid item xs={12} sm={5} style={{ overflowX: "hidden", overflow: "hidden" }} className="caixalogin">
            <Styles.Container className="formdelogin" >
              <div className="box-login">
                <Styles.EcomLogo src={images.logonovo} className="logonovo" />
                <h1 className="bemvindo">Seja bem-vindo,
                  <br/><span>agente Ecom!</span></h1>
                <p className="paragrafo-bemvindo">Entre aqui com seus dados para acessar o sistema!</p>  
                <InputEmail
                  name="username"
                  label={texts.user}
                  required={texts.userMessage}
                  classe="inputnovo"
                />
                <InputPassword
                  name="password"
                  label={texts.password}
                  required={texts.passwordMessage}
                  validation={validations.isPassword}
                  className="inputnovo"
                  onChange={() => {
                    setButton(false);
                  }}
                />
                <CheckBox
                  actualValue={reminder}
                  name="reminder"
                  label={texts.reminder}
                  click={() => setReminder(!reminder)}
                />
                <Grid
                  container
                  spacing={0}
                >
                    <Grid item xs={12} sm={6}>
                      <ButtonContained
                      name="submit"
                      loading={loading}
                      disabled={buttonDisabled}
                      feedback={true}
                      action="submit"
                      type="submit"
                      label={texts.enter}
                      className="botaonovo"
                    />
                    </Grid>
                    <Grid className="gridbotoes" item xs={12} sm={6}>
                            <Button
                              variant="contained"
                              onClick={() => navigate("/user_register")}
                              className="botaoacoes"
                              >
                                Ainda não tem conta? Crie aqui
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => navigate("/forgetPassword")}
                              className="botaoacoes"
                              >
                                Esqueci minha senha
                            </Button>

                        
                    </Grid>

                </Grid>
                  
                
                
                <Styles.Version>
                  <CustomText
                    fontSize={1.5}
                    fontFamily={fonts.light}
                    styles={{ color: "#C2C2C2", marginTop: Spacing(2) }}>
                    Parceiro Ecom v.{version} {process.env.REACT_APP_ENV}
                  </CustomText>
                </Styles.Version>
              </div>
            </Styles.Container>
          </Grid>

          <Grid item xs={0} sm={7} className="gridcarafalando">
            <ImageLogin2 />
            {/* <img src={images.falandocelular} className="imagemfalando" /> */}
          </Grid>
        </Grid>
      </FormFull>
      <ToastContainer />
    </div>
  );
}

export default Login;
